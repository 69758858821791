import cdng from "../assets/zeitgeistkuc.in.png";
import codeathon from "../assets/event/codeathon.jpg"
import web from "../assets/event/web-designing.jpg";
import troubleshoot from "../assets/event/troubleshoot.png";
import blindcoding from "../assets/event/blindcoding.jpg";
import fun from "../assets/event/fun.jpg";
import games from "../assets/event/games.jpg";
import enigma from "../assets/event/enigma.jpg";
import eureka from "../assets/event/eureka.jpg";
import vistalens from "../assets/event/vistalens.jpg";
import opsis from "../assets/event/opsis.jpg";
import iconaquest from "../assets/event/iconaquest.jpg";
import zaul from "../assets/event/zaul.jpg";
import vr from "../assets/event/vr.jpg";
import pc from "../assets/event/pc.jpg";
import henna from "../assets/event/henna.jpg";

export const allEvents = [
  {
    name: "coding",
    thumbnail: cdng,
    route: "coding",
    events: [
      {
        title: "codeathon",
        route: "codeathon",
        image: codeathon,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "A fast-paced coding competition where participants solve intricate problems within a 24-hour timeframe, showcasing their skills and innovation.",
        team: "Maximum 2",
        prize1: "₹6000",
        prize2: "No",
        date: "05-March & 06-March",
        contact: "+917356539215",
        form: "",
        fee:"Free",
        head: "NAVANEETH S",
        rules:"1. Individual participation allowed.\n2. Participants must bring their laptops.\n3. Ensure necessary software is installed.\n4. All contestants receive the same set of topics.\n5. 24-hour duration for the codeathon.\n6. Project must be related to the selected topic.\n7. Problems have assigned point values based on difficulty.\n8. Evaluation includes examining topic relevance.\n9. Regular check-ins for participant support.\n10. Code and documentation submission required.\n11. Transparent scoring process with post-event feedback.\n 12. Participation team of maximum 2 members.",
      },
      {
        title: "web designing",
        route: "web_designing",
        image: web,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Participants must create a web design addressing a specified problem statement, focusing solely on the UI development for the website.",
        team: "1",
        prize1: "₹2000",
        prize2: "₹1000",
        date: "05-March",
        contact: "+919562461531",
        form: "",
        fee:"Free",
        head: "RAFSEENA K",
        rules:"1. Individual participation.\n2. There will be 2 rounds.\n3. Round 1 Aptitude test contains 20 questions.\n4. There will be General questions and domain specific questions.\n5. Final round will be designing a web interface.\n6. Assets will be provided.",
      },
      {
        title: "troubleshoot",
        route: "troubleshoot",
        image: troubleshoot,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Attendees will receive a program containing a few bugs and are tasked with eliminating errors to ensure the code runs smoothly.",
        team: "1",
        prize1: "₹3000",
        prize2: "₹1500",
        date: "06-March",
        contact: "+919188709542",
        form: "",
        fee:"Free",
        head: "ASWIN KOROTH",
        rules:"1. Individual participation.\n2. 2 Rounds.\n3. Round 1 - Aptitude test : contains 20 questions (general questions + domain specific questions).\n4. Round 2 - Troubleshooting program.\n5. Language - C++",
      },
      {
        title: "blind coding",
        route: "blind_coding",
        image: blindcoding,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Participants are required to code a program based on a given problem statement, while keeping the display screen turned off.",
        team: "1",
        prize1: "₹3000",
        prize2: "₹1500",
        date: "06-March",
        contact: "+919400701540",
        form: "",
        fee:"Free",
        head: "AJAY K",
        rules:"1. Individual participation.\n2. There will be 3 rounds.\n3. Round 1 -  Aptitude test(prelims).\n4. Round 2 - Aptitude test(mains).\n5. Final round - Blind coding challenge.",
      },
    ],
  },
  {
    name: "fun and literacy",
    thumbnail: fun,
    route: "fun_and_literacy",
    events: [
      {
        title: "enigma",
        route: "enigma",
        image: enigma,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Embark on an exhilarating treasure hunt adventure where players solve clues, navigate challenges, and follow a trail of hints to uncover hidden treasures.",
        team: "Maximum 4",
        prize1: "₹6000",
        prize2: "No",
        date: "05-March & 06-March",
        contact: "+918089907174",
        form: "",
        fee:"200",
        head: "MIDULAJ O P",
        rules:"1. Each group can contain maximum of 4 people.\n2. There will be 4 rounds.\n3. The event will be held over 2 days.\n4. Electronic devices are not allowed.\n5. The participants are not allowed to go outside the campus.",
      },
      {
        title: "vistalens",
        route: "vistalens",
        image: vistalens,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Vistalens involves recording dynamic visuals on electronic or streaming media, with participants creating videos based on assigned themes.",
        team: "1",
        prize1: "₹3000",
        prize2: "₹1500",
        date: "05-March & 06-March",
        contact: "+919207491945",
        form: "",
        fee:"30",
        head: "KRIPANTH MADHAV P",
        rules:"1. The videography theme will be provided on the first day of the fest.\n2. Individual participation.\n3. Participants must be present at the time of videography, and no substitutions are allowed.\n4. Uses of smart phones are allowed.\n5. Students are not allowed to go outside the campus.\n6. The judges will be the final authority, and their decisions will be final. In the case of any disputes, the judge’s decision will be binding and final.",
      },
      {
        title: "eureka",
        route: "eureka",
        image: eureka,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Eureka is a team-based general quiz featuring pairs of participants. Teams that successfully qualify in each round will emerge as the winners.",
        team: "Maximum 2",
        prize1: "₹5000",
        prize2: "₹3000",
        date: "05-March",
        contact: "+919544238758",
        form: "",
        fee:"50/head",
        head: "VYSHNAV K P",
        rules:"1. Team of two members can participate.\n2. Preliminary round (consist of 20 questions).\n3. Final round consist of 5 rounds.\n\nRound 1. Pounce round(2 questions for each team).\nRound 2. Bounce round(2 questions for each team).\nRound 3. Visual round(2 questions for each team).\nRound 4. Bidding round(2 questions for each team).",
      },
      {
        title: "icona quest",
        route: "icona_quest",
        image: iconaquest,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Icona Quest is a quiz centered around the theme of series and films.",
        team: "Maximum 2",
        prize1: "₹3000",
        prize2: "₹2000",
        date: "06-March",
        contact: "+917306150656",
        form: "",
        fee:"50/head",
        head: "SANDRA M",
        rules:"1. Participants: Team of 2 members.\n2. Test: 2 rounds.\n3. Round 1 - Prelims:  20 questions - Star marked questions have additional points.\n4. Round 2 - Mains: 4 parts.\nPart 1: 20 questions.\nPart 2: Visual based questions. - 8 questions.\nPart 3: Anagram round - 3 questions for each team .\nPart 4: Bonus round\n5. Tie Breaker questions.\n--- 4 teams for finals ---",
      },
      {
        title: "opsis",
        route: "opsis",
        image: opsis,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Opsis is a photography competition where participants, according to the given theme, are tasked with capturing four photos that unveil a captivating story.",
        team: "1",
        prize1: "₹3000",
        prize2: "₹1500",
        date: "05-March & 06-March",
        contact: "+918943659850",
        form: "",
        fee:"30",
        head: "AKSHAY K R",
        rules:"1. Individual participation.\n2. Use of smart phones are allowed.\n3. Only those taken from inside the campus are allowed.\n4. Other than smartphones, external gadgets such as cameras   are not allowed.\n5. Photostory theme will be given only after the program starts.\n6. Editing is not allowed.\n7. Four photo frames should contain the story.\n8. Judgment is purely based on talent,skills,creativity and effort.",
      },
      {
        title: "zaul",
        route: "zaul",
        image: zaul,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Zaul provides a platform for individuals to shine a light on their talents and capabilities.",
        team: "1",
        prize1: "₹5000",
        prize2: "No",
        date: "05-March & 06-March",
        contact: "+918129604486",
        form: "",
        fee:"Free",
        head: "ASWANTH SANIL M T",
        rules:"1. Individual participation.\n2. Total 5 rounds.\n3. Rounds will be Aptitude test, Polygram, GD, Multitasking and stage performance.\n4. Contest will be conducted over two days.",
      },
      {
        title: "Henna hues",
        route: "henna_hues",
        image: henna,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Live Mehandi Contest to show off your henna art skills! Create stunning designs and compete for recogination and prizes",
        team: "1",
        prize1: "₹2000",
        prize2: "₹1000",
        date: "06-March",
        contact: "+919496866601",
        form: "",
        fee:"50",
        head: "HARSHA",
        rules:"1. Theme: Indian Bridal design.\n2. Participants can apply henna on their own hand or any other person's hand.\n3. One side should be done up to elbow.\n4. Open to all (age limit between 18-35).\n5. Participants should bring their own henna cones, or you can purchase from stall.\n6. Time allotted:1.5 hrs.\n7. Judging criteria: Neatness, creativity & intricacy.",
      },
    ],
  },
  {
    name: "games",
    thumbnail: games,
    route: "games",
    events: [
      {
        title: "pc gaming",
        route: "pc_gaming",
        image: pc,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Engage in the ultimate PC gaming competition and showcase your skills in a battle for supremacy. Get set for thrills – the game reveal is just around the corner!",
        team: "Must have 3",
        prize1: "₹4000",
        prize2: "₹2000",
        date: "05-March",
        contact: "+917025596624",
        form: "",
        fee:"150(50/head)",
        head: "FARHATHULLA MENAYATH",
        rules:"1. Participation:Team of 3 members is Mandatory.\n2. Round:conducted in Knockout manner.\n3. Should play in given PC.\n4. No change of PC allowed.\n 5. fixed game 'Rocket league'",
      },
      {
        title: "VR gaming",
        route: "VR_gaming",
        image: vr,
        venue: "Kannur University Campus Mangattuparamba",
        desc: "Step into another world and become the hero – VR gaming lets you live the adventure. Get ready for the excitement as the game will be revealed soon!",
        team: "1",
        prize1: "₹3000",
        prize2: "₹1500",
        date: "05-March",
        contact: "+918111867410",
        form: "",
        fee:"50",
        head: "AJAY SUNIL",
        rules:"No",
      },
    ],
  },
];

// export const REG_LINK = "https://www.google.com"
export const REG_LINK = "https://forms.gle/qHeTqq74zTeBLbmRA"


export const filterEvents=(evt)=>{
  if(evt === "all"){
    let data = [].concat(...[...allEvents.map((e)=>{
      e.events.forEach(el => {
        el.category = e.route;
      });
      return e.events;
    })]);
    return [data];
  }
  return allEvents.filter((e)=>e.name===evt).map((e)=>{
    e.events.forEach(el => {
      el.category = e.route;
    });
    return e.events;
  });
}