import "../styles/Teamcard.css";

const TeamCard = (props) => {
    return (
        <div className="member">
            <div className="image"><img src={props.image} alt=""/></div>
            <div className="details">
                <span className="role">{props.title}</span>
                <span className="name">{props.name}</span>
                <a href={`mailto:${props.email}`} className="mailid">{props.email}</a>
                <a href={`tel:${props.mobile}`} className="phone">{props.mobile}</a>
            </div> 
        </div>
     );
}
 
export default TeamCard;