import { motion } from "framer-motion";
import { NavLink, Outlet } from "react-router-dom";
import "../styles/Events.css";
import { allEvents } from "../data/data";

const Events = () => {
  const categoryOnly = [{name:"all", route:""}, ...allEvents.map((i)=>i)];


  return (
    <motion.div
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
      className="event-container"
    >
      <nav className="event-toggles">
        {
          categoryOnly.map((i, k)=>
          <NavLink className={"inner-nav"} end={true} key={k} to={ i.route}>{i.name}</NavLink>
        )
        }
      </nav>
      <Outlet/>
    </motion.div>
  );
};

export default Events;
