import { Link, NavLink } from "react-router-dom";
import logo from "../assets/logo only blue.png";
import '../styles/Nav.css';
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';
// import { REG_LINK } from "../data/data";


const Navbar = () => {
    const [barState, setBarState] = useState(true);
  return (
    <header>
      <div className="first-block">
        <Link to={"/"} className="logo"><img src={logo} alt="" /></Link>
        <button id="ham" className="ham-btn" onClick={()=>setBarState(!barState)}>
          <FontAwesomeIcon className="bar-ico" icon={faBars}/>
        </button>
      </div>
      <nav id="nav" className={`${barState?"close":""}`}>
        <ul className="list">
          <li className="items">
            <NavLink to={'/'} className={"nav-link"}>Home</NavLink>
          </li>
          <li className="items">
            <NavLink to={'/events'} className={"nav-link"}>Events</NavLink>
          </li>
          {/* uncomment this to add schedule link  */}
          <li className="items">
            <NavLink to={'/schedule'} className={"nav-link"}>Schedule</NavLink>
          </li>
          <li className="items">
            <NavLink to={'/team'} className={"nav-link"}>Team</NavLink>
          </li>
          <li className="items">
            <NavLink to={'/about'} className={"nav-link"}>About</NavLink>
          </li>
          {/* <li className="items">
            <a href={REG_LINK} target={"_blank"} className={"nav-link"}>Register</a>
          </li> */}
          <li className="items">
            <NavLink to={'/register'} className={"nav-link"}>Register</NavLink>
          </li>
          <li className="items">
            <NavLink to={'/download'} className={"nav-link"}>Download</NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
