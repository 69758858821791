import Card from "./Card";
import { allEvents } from "../data/data";
import "../styles/HomeSection3.css";

const HomeSection3 = () => {
    return ( 
        <section className="container-3">
        <div className="inner-title title-rotate">Events</div>
        <div className="event-grid">
          {
            allEvents.map((i, k)=>{
              return <Card key={k} name={i.name} image={i.thumbnail} route={`events/${i.route}`}/>
            })
          }
        </div>
      </section>
     );
}
 
export default HomeSection3;