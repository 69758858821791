import "../styles/Error.css";

const Error = () => {
  return (
  <div className="errormain">
    ERROR<br/>404
  </div>
  )
};

export default Error;
