import "../styles/Home.css";
import HomeSection1 from "../innnerComponents/HomeSection1";
import HomeSection2 from "../innnerComponents/HomeSection2";
import HomeSection3 from "../innnerComponents/HomeSection3";

const Home = () => {
  
  return (
    <>
    <HomeSection1/>
    <HomeSection2/>
    <HomeSection3/>
    </>
  );
};

export default Home;
