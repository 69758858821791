import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/Footer.css";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="foot-box-1">
          <div className="foot-title">
            Contact us <FontAwesomeIcon icon={faPhone} />
          </div>
          <ul className="contact-list">
            <li>
              <a href="mailto:zeitgeist.kuc@gmail.com">
                zeitgeist.kuc@gmail.com
              </a>
            </li>
            <li>
              <a href="tel:7510160065">9539385206</a>
            </li>
          </ul>
          <div className="socials">
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://instagram.com/zeitgeist.kuc?igshid=NTdlMDg3MTY="
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://instagram.com/zeitgeist.kuc?igshid=NTdlMDg3MTY="
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://wa.me/9539385206"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div>
        </div>
        <div className="foot-box-2">
          <div className="foot-title">sitemap</div>
          <ul className="contact-list">
            <li>
              <Link to={"/"}>home</Link>
            </li>
            <li>
              <Link to={"/events"}>events</Link>
            </li>
            <li>
              <Link to={"/schedule"}>schedule</Link>
            </li>
            <li>
              <Link to={"/team"}>team</Link>
            </li>
            <li>
              <Link to={"/register"}>register</Link>
            </li>
            <li>
              <Link to={"/download"}>download</Link>
            </li>
          </ul>
        </div>
        <div className="foot-box-3">
          <div className="foot-title">Location</div>
          <iframe
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3902.8783050135744!2d75.36344121402222!3d11.982921639042889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba43e40629494dd%3A0x5864a69dc0ee8189!2sKannur%20University%20Mangattuparamba%20Campus!5e0!3m2!1sen!2sin!4v1669887214485!5m2!1sen!2sin"
            height="300"
            loading="lazy"
            title="KUC"
            titlereferrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
