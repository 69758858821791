import "../styles/Card.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Card = (props) => {
    return ( 
        <Link to={`${props?.route}`} className="card">
            <div className="card-thumbnail">
                {
                (props.image)?<LazyLoadImage src={props?.image} alt={props?.name}/>:<></>
            }
            </div>
            <div className="card-content">
                <div className="card-title">
                    {props?.name}
                </div>
            </div>
        </Link>
     );
}
 
export default Card;