import { useEffect } from "react";
import { useState } from "react";
import Card from "./Card";
import { filterEvents } from "../data/data";

const EventFilter = (props) => {
    const [data, setData] = useState();
    useEffect(()=>{
         setData(filterEvents(props.category)[0]);
     },[props.category]);
     return ( 
        <div className="event-cont-grid" style={{"color":"red"}}>
          {
               data?.map((e, k)=><Card key={k} name={e.title} image={e.image} route={`/events/${e.category}/${e.route}`}/>)
          }
        </div>
     );
}

export default EventFilter;
