import { useEffect, useRef } from "react";
import Typed from "typed.js";
// import { Link, NavLink } from "react-router-dom";
import "../styles/HomeSection1.css";
import img from "../assets/vrim3.jpg";
import { REG_LINK } from "../data/data";


const HomeSection1 = () => {
    const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["National level IT fest ", "decode the future "],
      startDelay: 100,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 500,
      showCursor: true,
      loop:true
    });

    return () => {
      typed.destroy();
    };
  }, []);
    return ( 
        <section className="container-1">
        <div className="left">
          <div className="left-content">
              <h1 className="lfirst" >zeitgeist</h1>
              <h1 className="lsecond">05,06 mar '24</h1>
              <div className="lthird" ><span ref={el}></span></div>
              {/* <div className="lfourth">
              <NavLink to={'/register'} className={"reg-btn"}>Register</NavLink>
              </div> */}
              <div className="lfourth">
                {/* <a href={REG_LINK} target="_blank" className="reg-btn"> */}
                <a href={REG_LINK} target="" className="reg-btn">
                  Register
                </a>
              </div>
          </div>
        </div>
        <div className="right">
          <img src={img} alt="" />
        </div>
      </section>
     );
}
 
export default HomeSection1;