import "../styles/About.css";

const About = () => {
  return (
    <div className="about-container">
        <div className="head">ABOUT</div>
        <div className="content">
        <p>Zeitgeist, National level IT Fest, conducted by Department of Information Science and Technology - Kannur University since 2015, provides a platfrom for a multitude of students to showcase their technical ingenuity and prowess.
        Zeitgeist is a two-day fest with expected active participation of enormous number of students from several colleges across the state. It is one of the prominent event of the Kannur University. It serves as a perfect launch-pad for technology enthusiasts to showcase their intelligence and inventiveness. A massive platform for various activities organized in the form of competitive events, innovation challenges, exhibition, gaming and cultural events.
        This year a new platform for school level students to showcase their innovations are organised.
        Zeitgeist - Decode The Future -
        Come, Join and Thrive the Event.</p>
        </div>
      </div>
  );
};

export default About;