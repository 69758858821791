import { motion } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import "../styles/Eventdetails.css";
import { filterEvents } from "../data/data";

const NotFound = () =>{
    return(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
      className="evnt-dtl-not"
    >
        not found
    </motion.div>
    );
}

const Found = (props)=>{
  
  const rulesArray = props.data?.rules.split('\n');
    return(
        <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
      className="evnt-dtl-main"
    >
      <div className="evnt-dtl-container">
        <div className="evnt-dtl-details">
          <div className="evnt-dtl-date">
            <span className="evnt-dtl-name">{props.data?.title}</span>
            <span className="evnt-dtl-venue">
              <strong style={{ marginRight: "1rem",fontSize:"small" }}>Venue:</strong>
              <i style={{fontSize:"small"}}>{props.data?.venue}</i>
            </span>
            <span className="evnt-dtl-eventdate">
              <strong style={{ marginRight: "1rem",fontSize:"small" }}>Date:</strong>
              <i style={{fontSize:"small"}}>{props.data?.date}</i>
            </span>
            <span className="evnt-dtl-eventdate">
              <strong style={{ marginRight: "1rem",fontSize:"small" }}>Registration Fee:</strong>
              <i style={{fontSize:"small"}}>{props.data?.fee}</i>
            </span>
            {props.data?.prize1 === "No" ? (null) : 
              <span className="evnt-dtl-price">
                <strong style={{ marginRight: "1rem",fontSize:"small" }}>First Prize:</strong>
                <i style={{fontSize:"small"}}>{props.data?.prize1}</i>
              </span>
            } 
            {props.data?.prize2 === "No" ? (null) : 
              <span className="evnt-dtl-price">
                <strong style={{ marginRight: "1rem",fontSize:"small" }}>Second Prize:</strong>
                <i style={{fontSize:"small"}}>{props.data?.prize2}</i>
              </span>
            }
            {props.data?.team === "No" ? (null) : props.data?.team === "1"?
              <span className="evnt-dtl-teamnum">
                <strong style={{ marginRight: "1rem",fontSize:"small" }}>Participants:</strong>
                <i style={{fontSize:"small"}}>{props.data?.team} Member</i>
              </span>:
              <span className="evnt-dtl-teamnum">
                <strong style={{ marginRight: "1rem",fontSize:"small" }}>Team:</strong>
                <i style={{fontSize:"small"}}>{props.data?.team} Members</i>
              </span>
            }
            
          </div>
          <div className="evnt-dtl-image">
            <img src={props.data?.image} alt="" />
          </div>
        </div>
        <div className="evnt-dtl-discription">
          <span className="evnt-dtl-disc">DESCRIPTION</span>
          <span className="evnt-dtl-note">
            {
              props.data?.desc
            }
          </span>
        </div>
        
          {props.data?.rules === "No" ? (null) : 
            <div className="evnt-dtl-discription">
              <span className="evnt-dtl-disc">RULES</span>
              <span className="evnt-dtl-note">
              {rulesArray.map((line, index) => (
                <p key={index}>{line}</p>
              ))}
              </span>
            </div>
          }          
        
        <div className="detail-btn-grp">
        {/* <NavLink to={'/register'} className={"register-event"}>Register</NavLink> */}
          {/* <a href={props.data?.form} className="register-event">Register</a> */}
          
          <div className="contact-us">
          For More Details Contact {props.data?.head}
          <a href={`tel:${props.data?.contact}`} className="contact-coordinator">{props.data?.contact}</a>
          </div>
        </div>
      </div>
    </motion.div>
    )
}

const EventDetail = (props) => {
    const [data, setData] = useState();
    const name = useParams();
    useEffect(()=>{
        setData(filterEvents(props.category)[0].filter((e)=>e.route === name.event)[0]);
    },[props.category, name.event]);
  return (
    (data !== undefined) ? <Found data={data}/>:<NotFound/>
  );
};

export default EventDetail;
