import "../styles/HomeSection2.css";
import Slide from 'react-reveal/Slide';

const HomeSection2 = () => {
    return ( 
        <section className="container-2">
          <div className="container-2-left">
            <div
            className="itfest">
                <span>it</span><br/><span className="">fest</span>
            </div>
          </div>
          <div className="container-2-right">
            <Slide right >
            <div className="big-title-about">
                About
            </div>
            </Slide>
            <Slide right >
                <div className="sec-2-content" style={{fontSize:"small"}}>
                Zeitgeist is a national level IT fest conducted by Department of Information Science and Technology - Kannur University since 2015. It provides a platfrom for multitude of students to showcase their technical ingenuity and prowess.
            </div>
            </Slide>
          </div>
      </section>
     );
}
 
export default HomeSection2;