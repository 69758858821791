import React from 'react';
import "../styles/Download.css";
import events from "../assets/downloads/Events.pdf";
import icon from '../assets/download-icon.png';
import price from '../assets/downloads/prizebro.pdf';
import schedule from "../assets/downloads/schedule.pdf";

const DownloadPage = () => {
  const handleDownload = async (file,name) => {
    try {
      const response = await fetch(file);
      if (!response.ok) {
        throw new Error('Failed to fetch the PDF file');
      }
      const blob = await response.blob();
      const downloadLink = document.createElement('a');
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
  <div className='download'>
    <ul className="contact-list">
        {/* <li>
            <button className='download-btn' onClick={() => handleDownload(Brochure,'Brochure.pdf')}>
                <img src={icon} alt=''/>
            </button>
            <span className="download-name" onClick={() => handleDownload(Brochure,'Brochure.pdf')}>Download Brochure</span>            
        </li> */}
        {/* <li>
            <button className='download-btn' onClick={() => handleDownload(sponsor,'sponsor.png')}>
                <img src={icon} alt=''/>
            </button>
            <span className="download-name" onClick={() => handleDownload(sponsor,'sponsor.png')}>Download Sponsor Tariff</span>
        </li> */}
        <li>
            <button className='download-btn' onClick={() => handleDownload(events,'events.pdf')}>
                <img src={icon} alt=''/>
            </button>
            <span className="download-name" onClick={() => handleDownload(events,'events.pdf')}>Download Event Details</span>
        </li>
        <li>
            <button className='download-btn' onClick={() => handleDownload(events,'events.jpeg')}>
                <img src={icon} alt=''/>
            </button>
            <span className="download-name" onClick={() => handleDownload(schedule,'schedule.pdf')}>Download Schedule</span>
        </li>
        <li>
            <button className='download-btn' onClick={() => handleDownload(price,'price.pdf')}>
                <img src={icon} alt=''/>
            </button>
            <span className="download-name" onClick={() => handleDownload(price,'price.pdf')}>Download Prize Details</span>
        </li>
    </ul>
  </div>
  );
};

export default DownloadPage;
