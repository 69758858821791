import { motion } from "framer-motion";
import "../styles/Schedule.css";
import { eventSchedule } from "../data/EventSchedule";

const Schedule = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
      className="schedule-container"
    >
      <div className="big-title">Schedule</div>
      {eventSchedule.map((e, i) => {
        return (
          <>
          <div className="day">{e.date}</div>
          <div class="timeline" key={i}>
            <ul>
              {e.events.map((ein, ind) => {
                return (
                  <li key={ind}>
                    <div class="timeline-content">
                      <h3 class="date">{ein.time}</h3>
                      <h1>{ein.name}</h1>
                      <p>{ein.desc}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          </>
        );
      })}
    </motion.div>
  );
};

export default Schedule;
