import "../styles/Register.css";
import { REG_LINK } from "../data/data";


const Register = () => {

  return (
    <div className="reg-temp" style={{ "color": "#ffffff", "minHeight": "90vh", "display": "flex", "justifyContent": "center", "textAlign": "center", "verticalAlign": "middle", "alignItems": "center" }}>
      {/* Registration not started. Follow us on <a href="http://instagram.com/zeitgeist.kuc?igshid=NTdlMDg3MTY=" target="_blank" rel="noopener noreferrer"> instagram </a> to stay updated. */}
      To Register&nbsp;<a href={REG_LINK} target="_blank" rel="noopener noreferrer"> Click </a> &nbsp;Here.
    </div>
  );
};

export default Register;
