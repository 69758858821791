export const eventSchedule = [
    {
      date: "05 March 2024",
      events: [
        {
          time: "08:30am",
          name: "Registration",
          desc: "",
        },
        {
          time: "10:00am",
          name: "Inagural Function",
          desc: "At Seminar Complex",
        },
        {
          time: "11:00am",
          name: "Codeathon",
          desc: "",
        },
        {
          time: "11:30am",
          name: "Zaul - Star of Zeitgeist (Preliminary)",
          desc: "",
        },
        {
          time: "11:30am",
          name: "VR Gaming",
          desc: "",
        },
        {
          time: "11:30am",
          name: "Enigma - Treasure Hunt (Preliminary)",
          desc: "",
        },
        {
          time: "11:30am",
          name: "Opsis - Photo Story",
          desc: "",
        },
        {
          time: "11:30am",
          name: "Vistalens - Videography",
          desc: "",
        },
        {
          time: "01:30pm",
          name: "Eureka - General Quiz",
          desc: "",
        },
        {
          time: "01:30pm",
          name: "PC Gaming",
          desc: "",
        },
        {
          time: "01:50pm",
          name: "Web Designing",
          desc: "",
        },
        {
          time: "03:00pm",
          name: "Zaul - Star of Zeitgeist (Round 2)",
          desc: "",
        },
        {
          time: "03:20pm",
          name: "Enigma - Treasure Hunt (Round 2)",
          desc: "",
        },
        {
          time: "04:40pm",
          name: "Price Distribution",
          desc: "",
        },
      ],
    },
    {
      date: "06 March 2024",
      events: [
        {
          time: "09:30am",
          name: "TroubleShooting",
          desc: "",
        },
        {
          time: "09:40am",
          name: "Icona Quest - Pop Culture Quiz",
          desc: "",
        },
        {
          time: "10:30am",
          name: "Henna Hues - Mehandi contest",
          desc: "",
        },
        {
          time: "11:20am",
          name: "Enigma - Treasure Hunt (Round 3)",
          desc: "",
        },
        {
          time: "11:20am",
          name: "Zaul - Star of Zeitgeist (Round 3)",
          desc: "",
        },      
        {
          time: "11:30am",
          name: "Blind Coding",
          desc: "",
        },      
        {
          time: "01:40pm",
          name: "Enigma - Treasure Hunt (Final)",
          desc: "",
        },
        {
          time: "02:00pm",
          name: "Zaul - Star of Zeitgeist (Round 4 & Final)",
          desc: "",
        }, 
        {
          time: "03:30pm",
          name: "Alumni Meet-up",
          desc: "",
        },      
        {
          time: "04:30pm",
          name: "Price Distribution & Closing ceremony",
          desc: "",
        },      
      ],
    }
  ];
  