import "../styles/Team.css";
import TeamCard from "../innnerComponents/TeamCard";
import team from "../data/TeamMembers";
import { NavLink } from "react-router-dom";

const Team2024 = () => {
  return (
    <div>       
      <div className="team-container">
      <div className="big-title">Team</div>
        <div className="team-link">
          <ul className="list-year">
          <li className="items-year">
              <NavLink to={'/team/team2024'} className={"link"}>2024</NavLink>
            </li>
            <li className="items">
              <NavLink to={'/team/team2023'} className={"link"}>2023</NavLink>
            </li>
          </ul>
        </div>
        <div className="team-grid">
          {team.map((item, i) => (
            <TeamCard
              key={i}
              name={item.name}
              title={item.title}
              email={item.email}
              mobile={item.mobile}
              image={item.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team2024;
