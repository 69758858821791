import "../styles/App.css";
import Home from "./Home";
import Events from "./Events";
import Register from "./Register";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Navbar from "../innnerComponents/Navbar";
import Footer from "../innnerComponents/Footer";
import EventFilter from "../innnerComponents/EventFilter";
import Team2024 from "./Team2024";
import Team2023 from "./Team2023";
import About from "./About";
import { allEvents } from "../data/data";
import Error from "./Error";
import EventDetail from "./EventDetail";
import Schedule from "./Schedule";
import ScrollToTop from "../innnerComponents/ScrollToTop"
import DownloadPage from "./Download";

function App() {
  const categoryOnly = [...allEvents.map((i) => i)];
  return (
    <>
    <ScrollToTop/>
      <Navbar />
      <AnimatePresence >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="events" element={<Events />}>
            <Route path="" element={<EventFilter category={"all"} />} />
            {categoryOnly.map((i, k) => (
              <Route
                path={i.route}
                key={k}
                element={<EventFilter category={i.name} />}
              ></Route>
            ))}
          </Route>
          {
            categoryOnly.map((i, k)=><Route key={k} path={`events/${i.route}/:event`} element={<EventDetail category={i.name}/>} />)
          }
          <Route path="register" element={<Register />} />
          <Route path="team" element={<Team2024/>}></Route>
          <Route path="download" element={<DownloadPage/>}></Route>
          <Route path="team/team2024" element={<Team2024 />} />
          <Route path="team/team2023" element={<Team2023/>}/>
          <Route path="about" element={<About />}></Route>
          <Route path="schedule" element={<Schedule />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
