import ashif from "../assets/team/ashif.jpg"
import vivek from "../assets/team/vivek.jpg"
import nakul from "../assets/team/nakul.jpg"
import athul from "../assets/team/athul.jpg"
import jishnu2 from "../assets/team/jishnu2.jpg"
import shifana from "../assets/team/shifana.jpg"
import arathi from "../assets/team/arathi.jpg"
import rafsal from "../assets/team/rafsal.jpg"
import akshay from "../assets/team/akshay.jpg"


const team = [
    {
        name:"ASHIF CK",
        title: "CONVENER",
        email:"ashifck@zeitgeistkuc.in",
        mobile:"+91 95393 85206",
        image:ashif,
    },
    {
        name:"VIVEK RAM MJ",
        title: "CO-CONVENER",
        email:"vrammj00@gmail.com",
        mobile:"+91 94461 88524",
        image:vivek,
    },
    {
        name:"NAKUL VINAYAN",
        title: "CO-CONVENER",
        email:"nakulvinayanc@gmail.com",
        mobile:"+91 79944 27323",
        image:nakul,
    },
    {
        name:"ATHUL VINOD",
        title: "TREASURER",
        email:"athulvinod@zeitgeistkuc.in",
        mobile:"+91 92078 38897",
        image:athul,
    },
    {
        name:"JISHNU K T",
        title: "SUB-TREASURER",
        email:"jsn18j@gmail.com",
        mobile:"+91 81389 19696",
        image:jishnu2,
    },
    {
        name:"SHIFANA N V",
        title: "PROGRAM COORDINATOR",
        email:"shifananv@zeitgeistkuc.in",
        mobile:"+91 73069 01962",
        image:shifana,
    },
    {
        name:"ARATHI ASHOKAN",
        title: "SUB-PROGRAM COORDINATOR",
        email:"arathiashokan59@gmail.com",
        mobile:"+91 82819 06076",
        image:arathi,
    },
    {
        name:"MUHAMMED RAFSAL N",
        title: "DESIGN",
        email:"rafsalrz3@gmail.com",
        mobile:"+91 8606512302",
        image:rafsal,
    },
    {
        name:"AKSHAY K R",
        title: "DESIGN",
        email:"akshayakr4@gmail.com",
        mobile:"+91 89436 59850",
        image:akshay,
    },
    // {
    //     name:"MANUPRASAD K",
    //     title: "MARKETING AND MEDIA",
    //     email:"manupkm24@gmail.com",
    //     mobile:"+91 96330 20710",
    //     image:img,
    // },
    // {
    //     name:"RAFSEENA K",
    //     title: "MARKETING AND MEDIA",
    //     email:"rafseenak2001@gmail.com",
    //     mobile:"+91 95624 61531",
    //     image:img,
    // },
    // {
    //     name:"ASWIN KOROTH",
    //     title: "MARKETING AND MEDIA",
    //     email:"aswi2100@gmail.com",
    //     mobile:"+91 91887 09542",
    //     image:img,
    // },
]

export default team;