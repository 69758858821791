import bilal from "../assets/team/bilal.jpg"
import aswin from "../assets/team/aswin.jpg"
import jishnu from "../assets/team/jishnu.jpg"
import sreekanth from "../assets/team/sreekanth.jpeg"
import theertha from "../assets/team/theertha.jpg"

const team2023 = [
    {
        name:"ASWIN N",
        title: "CONVENER",
        email:"045asw@gmail.com",
        mobile:"+91 7510160065",
        image:aswin,
    },
    {
        name:"RUBEEN BILAL C",
        title: "CO-CONVENER",
        email:"rubeenbilal47@gmail.com",
        mobile:"+91 7356007368",
        image:bilal,
    },
    {
        name:"THEERTHA ANOOP",
        title: "CO-CONVENER",
        email:"theethu1908@gmail.com",
        mobile:"+91 8137050362",
        image:theertha,
    },
    {
        name:"SREEKANTH K K",
        title: "TREASURER",
        email:"sreekanthkoyyam@gmail.com",
        mobile:"+91 9747720341",
        image:sreekanth,
    },
    {
        name:"JISHNU K T",
        title: "SUB-TREASURER",
        email:"jsn18j@gmail.com",
        mobile:"+91 8138919696",
        image:jishnu,
    },
]
export default team2023;